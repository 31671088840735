import VuexORM, { Model, Database } from '@vuex-orm/core';
import VuexORMAxios from '@vuex-orm/plugin-axios';
import axios from 'axios';

const ORMInstall = (modules: Array<typeof Model> | null = null, store: any) => {
  VuexORM.use(VuexORMAxios, { axios });

  const database: Database = new VuexORM.Database();

  if (modules !== null) {
    modules.forEach((model: typeof Model) => {
      database.register(model);
    });
  }

  VuexORM.install(database)(store);
};

export { ORMInstall };
